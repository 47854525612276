@import "~bootswatch/dist/darkly/variables";

$link-color-dark: $yellow;
$carousel-dark-caption-color: $white;
$carousel-dark-indicator-active-bg: $white;
$carousel-dark-control-icon-filter: unset;

@import "~bootstrap/scss/bootstrap";

$web-font-path: "google-fonts.css";
@import "~bootswatch/dist/darkly/bootswatch";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

$social-icon-colors: (
        "github": #4e8bc7,
        "gitlab": #e04229,
        "lastfm": #ba0000,
        "reddit": #fd4400,
        "steam": #bebcb9,
        "telegram": #27a6e3,
        "twitter": #1c9fef,
        "xing": #006567,
        "youtube": #fd0000
);

$project-categories: "applications", "minecraft-plugins", "php-libraries";

.project-list-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
    min-height: 200px;
    min-width: 100%;
}

.project-carousel-inner {
    overflow: visible;
}

.carousel-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    min-height: 400px;
    min-width: 100%;
}

.social-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 20px;
    padding: 3px;
    margin-bottom: 10px;
    color: #fff;
    transition: all .3s ease-in-out;
}

@each $name, $color in $social-icon-colors {
    .social-icon-#{$name}:hover,
    .social-icon-#{$name}:focus,
    .social-icon-#{$name}:active {
        color: $color;
    }
}

html {
    scroll-behavior: smooth;
}

main {
    padding-top: 70px;
}

footer {
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: $gray-800;
}

main > .container {
    margin-top: 20px;
}

pre {
    background: $gray-800;
    color: $gray-300;
    padding: $spacer * .5;
    border-radius: .25rem;
}

nav.navbar {
    background-color: $gray-800;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3) 100%);
    display: block;
    padding: 0;
}

nav.navbar > .container {
    padding: 1em;
}

.nav-item {
    cursor: pointer;
}

.navbar-brand {
    padding: 0.5rem;
}

.project-list-anchor {
    scroll-margin-top: 85px;
}

.release-notes {
    --bs-card-bg: var(--bs-dark-bg-subtle);
}

.release-notes .card-header {
    background-color: var(--bs-primary-bg-subtle);
}

.release-notes .card-body > *:last-child {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .nav-item, #navbar-collapse {
        display: inline-block !important;
    }

    .nav-item.active, .navbar-brand.active {
        position: relative;
    }

    .nav-item.active:after, .navbar-brand.active:after {
        transform: rotateZ(45deg);
        width: 0.75em;
        height: 0.75em;
        content: '';
        display: block;
        position: absolute;
        bottom: -1em;
        left: 50%;
        margin-left: -0.375em;
        background-color: $yellow;
    }
}

nav.navbar:after {
    content: '';
    display: block;
    width: 100%;
    height: 0.75em;
    background-color: $yellow;
}

a {
    text-decoration: none;
}

@each $category in $project-categories {
    #home-carousel-#{$category}-cover {
        background-image: url("../images/categories/#{$category}.jpg");
    }
}

#about-avatar {
    background-image: url("../images/avatar.png");
    background-repeat: no-repeat;
    background-size: 128px;
    width: 128px;
    height: 128px;
    margin-top: auto;
    margin-bottom: auto;
}

#imprint-support {
    display: block;
    background-image: url("../images/contact.png");
    background-repeat: no-repeat;
    width: 168px;
    height: 23px;
}